/*Site*/
body {
    background-color: #e8e8e8;
  }
  .wl-btn-click {
    box-shadow: 1px 1px 1px #00000059;
    transform: translateY(2px);
  }
  .wl-btn-mouseover {
    background-color: #148abe;
    box-shadow: 3px 3px 3px #00000059;
  }
  .wl-btn-mouseover-danger {
    background-color: #d43f3a;
    color: white;
    box-shadow: 3px 3px 3px #00000059;
  }
  .wl-btn-mouseover-success {
    background-color: #4cae4c;
    color: white;
    box-shadow: 3px 3px 3px #00000059;
  }
  .wl-btn-primary {
    /* width: 140px;*/
    /*height: 40px;*/
    padding: 8px 15px;
    color: #fff;
    background: inherit;
    background-color: #169bd5;
    border: none;
    border-radius: 5px;
    box-shadow: 2px 2px 2px #00000059;
    margin-bottom: 8px;
    margin-right: 4px;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .wl-btn-primary:active {
    box-shadow: 1px 1px 1px #00000059;
    transform: translateY(2px);
  }
  .wl-btn-primary:hover {
    background-color: #148abe;
    box-shadow: 3px 3px 3px #00000059;
  }
  .wl-btn-danger {
    /* width: 140px;*/
    /*height: 40px;*/
    padding: 8px 15px;
    color: #fff;
    background: inherit;
    background-color: #d9534f;
    border: none;
    border-radius: 5px;
    box-shadow: 2px 2px 2px #00000059;
    margin-bottom: 8px;
    margin-right: 4px;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .wl-btn-danger:active {
    box-shadow: 1px 1px 1px #00000059;
    transform: translateY(2px);
  }
  .wl-btn-danger:hover {
    background-color: #d43f3a;
    color: white;
    box-shadow: 3px 3px 3px #00000059;
  }
  .wl-btn-success {
    /* width: 140px;*/
    /*height: 40px;*/
    padding: 8px 15px;
    color: #fff;
    background: inherit;
    background-color: #d9534f;
    border: none;
    border-radius: 5px;
    box-shadow: 2px 2px 2px #00000059;
    margin-bottom: 8px;
    margin-right: 4px;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .wl-btn-success:active {
    box-shadow: 1px 1px 1px #00000059;
    transform: translateY(2px);
  }
  .wl-btn-success:hover {
    background-color: #4cae4c;
    color: white;
    box-shadow: 3px 3px 3px #00000059;
  }
  .wl-btn-hyperlink-primary {
    /* width: 140px;
      height: 40px;*/
    padding: 8px 15px;
    background: inherit;
    color: #fff;
    background-color: #169bd5;
    border: none;
    border-radius: 5px;
    box-shadow: 2px 2px 2px #00000059;
    cursor: pointer;
    margin-bottom: 8px;
    margin-right: 4px;
    text-decoration: none;
  }
  .wl-btn-hyperlink-primary:active {
    box-shadow: 1px 1px 1px #00000059;
    transform: translateY(2px);
    color: #fff;
    text-decoration: none;
  }
  .wl-btn-hyperlink-primary:hover {
    background-color: #148abe;
    box-shadow: 3px 3px 3px #00000059;
    color: #fff;
    text-decoration: none;
  }
  .wl-btn-hyperlink-primary:focus {
    background-color: #148abe;
    box-shadow: 3px 3px 3px #00000059;
    text-decoration: none;
  }
  .wl-btn-hyperlink-primary:visited {
    color: #fff;
    background-color: #169bd5;
  }
  .wl-hyperlink-nav-btn {
    font-size: 25px;
    color: #666;
    padding: 0px 32px;
  }
  .wl-hyperlink-nav-btn:hover {
    color: #666;
    text-decoration: none;
  }
  .wl-hyperlink-nav-btn-active {
    color: #8080cc;
    font-weight: 800 !important;
  }
  .wl-hyperlink-nav-btn-active:hover {
    color: #8080cc;
    text-decoration: none;
  }
  .wl-action-btn-mini {
    width: 71px;
    height: 25px;
    background: inherit;
    background-color: #e8e8e8;
    border: none;
    border-radius: 5px;
    box-shadow: none;
    font-family: 'Arial Bold', 'Arial';
    font-weight: 700;
    font-style: normal;
    margin-bottom: 4px;
  }
  .wl-action-btn-mini:hover {
    background-color: #148abe;
    box-shadow: 3px 3px 3px #00000059;
  }
  .wl-action-btn-hyperlink-mini {
    width: 71px;
    height: 25px;
    background: inherit;
    background-color: #e8e8e8;
    border: none;
    border-radius: 5px;
    box-shadow: none;
    font-family: 'Arial Bold', 'Arial';
    font-weight: 700;
    font-style: normal;
    margin-bottom: 4px;
  }
  .wl-action-btn-hyperlink-mini:hover {
    background-color: #148abe;
    box-shadow: 3px 3px 3px #00000059;
    text-decoration: none;
  }
  .wl-action-btn-hyperlink-mini:visited {
    background-color: #e8e8e8;
  }
  .wl-btn-clearall {
    background-color: #fff !important;
    color: #0066ff !important;
    border: 1px solid #0066ff;
  }
  .wl-btn-clearall:hover {
    background-color: #fff !important;
    color: #0066ff !important;
    border: 1px solid #0066ff;
  }
  .wl-hidden-button {
    display: none;
  }
  .wl-action-btn-white {
    padding-left: 10px;
    padding-right: 10px;
    height: 27px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: none;
    font-family: 'Arial Bold', 'Arial';
    font-weight: 700;
    font-style: normal;
    margin-bottom: 4px;
    margin-right: 4px;
    box-shadow: 1px 1px 1px #0000002b;
  }
  .wl-action-btn-white:hover {
    box-shadow: 2px 2px 2px #0000002b;
  }
  .wl-action-btn-green {
    background-color: #00CC33;
    color: white;
  }
  .wl-action-btn-green:hover {
    box-shadow: 2px 2px 2px #0000002b;
  }
  .wl-action-btn-blue {
    background-color: #169bd5;
    border: 1px solid #169bd5 !important;
    color: white;
  }
  .wl-action-btn-blue:hover {
    box-shadow: 2px 2px 2px #0000002b;
  }
  .wl-action-btn-red {
    background-color: #FF0000;
    color: white;
  }
  .wl-action-btn-red:hover {
    box-shadow: 2px 2px 2px #0000002b;
  }
  .wl-action-btn-orange {
    background-color: #f0ad4e;
    color: white;
  }
  .wl-action-btn-orange:hover {
    box-shadow: 2px 2px 2px #0000002b;
  }
  .wl-tab-primary {
    width: 127px;
    height: 48px;
    background: inherit;
    background-color: #0000997f;
    border: none;
    border-bottom: 0px;
    border-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Arial Bold', 'Arial';
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: #FFFFFF;
  }
  .wl-tab-body-primary {
    box-sizing: border-box;
    border-width: 3px;
    border-style: solid;
    border-color: #8080cc;
    border-radius: 0px;
    box-shadow: none;
  }
  .wl-panel-primary {
    border: 1px solid #66ccff !important;
    border-radius: 0 !important;
    box-shadow: 3px 3px 3px #9a9a9a !important;
  }
  .wl-panel-primary-heading {
    background-color: #66ccff;
    border-radius: 0 !important;
    color: #fff;
    font-weight: 700;
    padding: 10px;
  }
  .wl-panel-primary-body {
    border-radius: 0 !important;
    padding: 10px !important;
    background-color: #fff !important;
  }
  .wl-panel-secondary {
    border: 1px solid #66ccff;
    border-radius: 0;
    box-shadow: 3px 3px 3px #9a9a9a;
  }
  .wl-panel-secondary-heading {
    background-color: #66ccff;
    color: #fff;
    font-weight: 700;
    padding: 10px;
  }
  .wl-panel-secondary-body {
    border-radius: 0;
    padding: 10px;
    background-color: #fff;
  }
  .wl-panel-secondary-red {
    border: 1px solid #d9534f;
  }
  .wl-panel-secondary-heading-red {
    background-color: #d9534f;
  }
  .wl-container-primary {
    background-color: #f2f2f2;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.35);
  }
  .wl-container-fluid {
    background-color: #f2f2f2;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 80px;
    padding: 10px;
  }
  .wl-header-fluid {
    padding: 8px 10px;
    position: relative;
    top: -46px;
    left: -10px;
    border-radius: 0px;
    background-color: #666 !important;
    color: #fff;
  }
  .wl-label-grey {
    height: 32px;
    background: inherit;
    background-color: #666666;
    border: none;
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Arial Bold', 'Arial';
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    color: #FFFFFF;
  }
  .wl-label-grey-detail {
    height: 22px !important;
  }
  .wl-label-purple {
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
    height: 32px;
    background: inherit;
    background-color: #8080cc;
    border: none;
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Arial Bold', 'Arial';
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    color: #FFFFFF;
    text-align: left;
  }
  /*jquery ui dialog close button icon error fix*/
  .ui-dialog-titlebar-close {
    padding: 0 !important;
  }
  .ui-dialog-titlebar-close:after {
    content: '';
    width: 20px;
    height: 20px;
    display: inline-block;
    /* Change path to image*/
    /* background-image: url(themes/base/images/ui-icons_777777_256x240.png); */
    background-position: -96px -128px;
    background-repeat: no-repeat;
  }
  /*Input group customization*/
  .wl-input-group-title {
    border: 0 !important;
    background: inherit !important;
    font-weight: 700 !important;
  }
  .wl-input-group-radius-fix {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  /*Tables*/
  .wl-gridtable-div {
    border: 1px solid #ddd;
    padding: 20px;
    background-color: #fff;
  }
  .wl-gridtable-default tr th {
    color: black !important;
  }
  /*data container tabs*/
  .wl-datacontainer {
    min-width: 800px;
    min-height: 800px;
    /*max-width: 1600px;*/
    /*max-height:800px;*/
    background-color: #f2f2f2;
    /*margin:0px 80px 20px 20px;*/
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 80px;
    padding: 20px;
  }
  .wl-datacontainer-span-header {
    padding: 8px 10px;
    position: relative;
    top: -65px;
    left: -20px;
    border-radius: 0px;
    background-color: #666 !important;
    display: inline-grid;
    color: #fff;
  }
  .wl-span-header-div {
    padding: 8px 10px;
    border-radius: 0;
    background-color: #666 !important;
    display: inline-grid;
    color: #fff;
  }
  .wl-datacontainer-title-center {
    text-align: center;
  }
  .wl-datacontainer-title-center > span {
    font-size: 25px;
    color: #8080cc;
  }
  .wl-form-control {
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
  }
  .wl-nav-pills > li.active > a,
  .wl-nav-pills > li.active > a:hover,
  .wl-nav-pills > li.active > a:focus {
    background-color: #8080ccd3 !important;
    color: #fff;
    font-weight: 700;
  }
  .wl-nav-pills {
    border: 1px solid #8080ccd3 !important;
    width: fit-content;
    background-color: #fff;
    border-radius: 4px;
  }
  .wl-nav-pills > li > a {
    padding: 6px 45px;
    border-radius: 2px;
    color: #666666;
    font-weight: 700;
  }